import React from 'react'
import {Link} from 'react-router-dom'
import './Form.scss'

export default function () {
  return(
    <div className="form-wrapper-contact">
      <form name="contact" method="POST" data-netlify="true">
        <h2>Opening in May 2022!</h2>

        {/* <p>BUT we still do <Link to="/private-events">catering</Link>!</p> */}


        <p>Sign up to be notified of specials!</p>

        {/* <p>Sign up to be notified when we open</p> */}


        <input type="hidden" name="form-name" value="contact" />
        <p>
          <label>Your Name: <input type="text" name="name" /></label>
        </p>
        <p>
          <label>Your Email: <input type="email" name="email" /></label>
        </p>
        {/* <p>
          <label>Optional Zipcode: (to help us plan new locations)
          <br/><input type="zipcode" name="zipcode" /></label>
        </p> */}
        {/* <p>
          <label>Message: <textarea name="message"></textarea></label>
        </p> */}
        <p>
          <button type="submit">Submit</button>
        </p>
      </form>
    </div>
  )
}
