import React from "react"

function SvgComponent(props) {
  const themeName = props.themeName || 'light'
  const fillColor = (themeName === 'dark') ? '#ffc904' : '#111111'
  return (
    <svg
      fill={fillColor}
      viewBox="0 0 246.4 123.6" {...props}>
      <path d="M91 79.2V3.9h48.8v17.2h-25.5v10.1h25.5v16.3h-25.5V62h25.5v17.2H91zM151 79.2V3.9h23.3v56.5h23.1v18.9H151zM228.3 22.8v56.5H205V22.8h-14.8V3.9H243v18.9h-14.7z" />
      <g>
        <path d="M15.8 120.5c-9.4 0-13.3-7.3-13.3-16.8s4-16.8 13.3-16.8c9.6 0 13.3 7.1 13.3 16.8 0 9.4-4 16.8-13.3 16.8zm0-26.1c-2.5 0-3.4 3-3.4 9.3 0 6.4.9 9.3 3.4 9.3 2.6 0 3.4-3 3.4-9.3 0-6.6-.9-9.3-3.4-9.3zM58.9 120.2h-1.4L43 107.6v12.1h-9.8V87.2h1.4L49 99.7v-12h9.8v32.5zM107.8 119.9h-6.4L96.3 108l-5.1 11.9h-6.4L78 87.8h9.7l2.9 14.7 5.2-12.2h.9l5.3 12.1 2.7-14.7h9.8l-6.7 32.2zM133.9 119.6v-13.2h-6v13.2h-9.8V87.7h9.8v10.9h6V87.7h9.9v31.9h-9.9zM149.2 119.6V87.7h20.6V95H159v4.3h10.8v6.9H159v6.2h10.8v7.3h-20.6zM174.6 119.6V87.7h20.6V95h-10.8v4.3h10.8v6.9h-10.8v6.2h10.8v7.3h-20.6zM200 119.6V87.7h9.8v23.9h9.8v8H200zM226 120c-1.4 0-2.7 0-4.1-.1l.4-7.9c1.6.3 3.4.5 5 .5 4.3 0 5.6-.9 5.6-2.1 0-1.3-1.2-2.1-4.4-3.8-4.1-2.1-6.9-4.8-6.9-9.3 0-6.5 6.2-9.7 15-9.7 1.6 0 3.4.1 5.1.3l-.4 7.8c-1.6-.3-3.4-.5-5.2-.5-3.6 0-4.6.7-4.6 1.6 0 1.2 1.5 1.8 4.2 3.3 4.2 2.1 7.1 4.5 7.1 9.5-.1 7.4-7.6 10.4-16.8 10.4z" />
      </g>
      <path d="M74.8 2.9l-33 29.5L9 2.9H5.7v27.8H14v3h-2.3v8.9H7.9v-8.9H5.7v45.6H29V46.9l11.9 11.6h1.7l12.2-12v32.7H78V2.9h-3.2zM24.7 42.5H21v-4.9h-2.2v4.9h-3.7V30.7h3.7v4.1H21v-4.1h3.7v11.8zm9.8-9.1h-4V35h4v2.6h-4v2.3h4v2.7h-7.7V30.7h7.7v2.7z" />
    </svg>
  )
}

export default SvgComponent
